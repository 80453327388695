import React from "react";
import { notification, Icon } from "antd";
import "./index.less";
export default class SystemUpdate extends React.Component {
  componentDidMount() {
    this.heartAction();
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  heartAction() {
    clearTimeout(this.timer);
    this.timer = setTimeout(async () => {
      const result = await Service.other.systemUpdate();
      if (!result.isUpdate) {
        notification.open({
          message: "系统提示",
          description: "系统维护已完成，页面即将刷新！",
          duration: 0,
          icon: <Icon type="info-circle" style={{ color: "#108ee9" }} />,
          className: "server-worker-notify"
        });

        setTimeout(() => {
          window.location.reload();
        }, 3 * 1000);
      } else {
        this.heartAction();
      }
    }, 1000);
  }
  render() {
    return (
      <div className="system-update-layout">
        <header>
          <a class="logo" href="/">
            {/* 智慧云眼 */}
          </a>
        </header>
        <div class="img" />
        <p>
          系统正在维护中，请稍后访问<i>.</i>
          <i>.</i>
          <i>.</i>
        </p>
      </div>
    );
  }
}
